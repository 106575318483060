

<template>
  <vx-card>
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removeActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >ลบสินค้า {{this.removeCode}}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button
              @click="removeOrder()"
              style="margin:10px;"
              color="success"
              type="filled"
            >ตกลง</vs-button>
            <vs-button
              @click="closeRemovePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="ข้อมูลเพิ่มเติมในการคืนชุด"
        :active.sync="popupActive"
      >
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--------------------------------------------------------------------->

        <!-- BEGIN: RETURN DETAIL -->

        <div class="grid-layout-container alignment-block label-font" style=" width:100%;">
          <!-- <h3 style="padding-left : 30px; margin-top 50px;">ข้อมูลเพิ่มเติมในการคืนชุด</h3> -->
          <!--  โน้ต/หมายเหตุ  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >โน้ต/หมายเหตุ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-textarea
                class="w-full"
                name="remark"
                v-model="remark"
                style="border: 1px #cccccc solid; "
              />
            </vs-col>
          </vs-row>
          <!--  หักเงินประกัน  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >หักเงินประกัน</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <div
                class="grid-layout-container alignment-block"
                style="vertical-align:text-top ;width:100%;"
              >
                <vs-row vs-w="12">
                  <CurrencyValue class="w-full" v-model="deduction"></CurrencyValue>
                </vs-row>
                <vs-row vs-w="12">
                  <div
                    class="little-comment the-blue"
                  >(วงเงินประกัน {{formatPrice(bailTotal - bailDiscount)}} บาท)</div>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>

          <!--  ธนาคาร  -->
          <vs-row style="font-weight:bold; margin-top:20px; " class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >ธนาคาร</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="bankName" v-model="bankName" />
            </vs-col>
          </vs-row>

          <!--  ชื่อบัญชีลูกค้า  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >ชื่อบัญชีลูกค้า</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="accountName" v-model="accountName" />
            </vs-col>
          </vs-row>

          <!--  เลขที่บัญชีลูกค้า  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >เลขที่บัญชีลูกค้า</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="accountNumber" v-model="accountNumber" />
            </vs-col>
          </vs-row>

          <!--  เบอร์ติดต่อ  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >เบอร์ติดต่อ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="transferContact" v-model="transferContact" />
            </vs-col>
          </vs-row>
        </div>
        <!-- </vs-table>END: RETURN DETAIL -->

        <!--------------------------------------------------------------------->
        <div style="border-top: 1px solid #E3E3E3; margin-bottom:10px;margin-top:20px; "></div>
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <!-- <vs-row vs-w="12" vs-type="flex" vs-justify="center">
          <vs-button
            @click="$router.back()"
            style="margin-right: 20px;"
            color="warning"
            type="filled"
          >กลับไปหน้าเช่า</vs-button>
          <vs-button color="success" type="filled">ยืนยันการเช่า</vs-button>
        </vs-row>-->
        <!--------------------------------------------------------------------->
        <vs-row vs-w="12" type="flex" vs-justify="flex-end">
          <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
          <vs-button @click="closePanel()" style="margin:10px;" color="danger" type="filled">ยกเลิก</vs-button>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <template>
      <!-- POP UP -->
      <vs-popup classContent="popup-example" title="บันทึกข้อความ" :active.sync="notePopupActive">
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--  Add this to data part  (import vSelect from "vue-select")     "v-select": vSelect  -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >บันทึกข้อความ</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-textarea v-model="remark"></vs-textarea>
          </vs-col>
        </vs-row>

        <vs-button @click="updatenote()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
        <vs-button
          @click="closeNotePanel()"
          style="margin:10px;"
          color="danger"
          type="filled"
        >ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <!-- BACK STATUS เปลี่ยนสถานะคืน -->
    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="เปลี่ยนสถานะคืน"
        :active.sync="backstatusPopupActive"
      >
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="10"
            vs-sm="10"
            vs-xs="10"
            class="form-label px-2"
          >
            เปลี่ยนสถานะ Order เลข
            <b>{{this.orderNumber}}</b> เป็นรอรับชุด
          </vs-col>
        </vs-row>

        <vs-button @click="updateback()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
        <vs-button
          @click="closeBackStatusNotePanel()"
          style="margin:10px;"
          color="danger"
          type="filled"
        >ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="6"
        class="px-2"
      >วันที่รับชุด</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="6">
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;"
        >
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="px-2"
            >
              <datepicker
                style="width:100%;"
                placeholder="วันเริ่ม"
                format="yyyy-MM-dd"
                v-model="searchDate"
              ></datepicker>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="px-2"
            >
              <datepicker
                style="width:100%;"
                placeholder="วันสิ้นสุด"
                format="yyyy-MM-dd"
                v-model="searchDateEnd"
              ></datepicker>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="12">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="searchBtn()" style="width:100%;" color="primary" type="filled">ค้นหา</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="resetBtn()" style="width:100%;" color="warning" type="filled">reset</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >
            <!-- <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="rgb(255, 114, 131)"
              type="filled"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >print</i>
              </span>
              <span style=" vertical-align:middle;">พิมพ์ใบเสร็จ</span>
            </vs-button>-->
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- BRANCHES -->
    <!-- <vs-row vs-w="12">
      <ul v-if="permission === 'admin'" class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in allBranch"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>
    </vs-row>-->
    <!-- BRANCHES -->
    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table style="z-index:1;" pagination max-items="20" search :data="orders">
      <template style="z-index:1 !important;" slot="thead">
        <!-- <vs-th>
          <vs-checkbox v-if="selectStatus===0" v-model="selectAll" @click="selectAllOrders($event)"></vs-checkbox>
          <vs-checkbox
            v-else-if="selectStatus===1"
            icon-pack="feather"
            icon="icon-check"
            v-model="selectAll"
            @click="selectAllOrders($event)"
          ></vs-checkbox>
          <vs-checkbox
            v-else
            icon-pack="feather"
            icon="icon-minus"
            v-model="selectAll"
            @click="selectAllOrders($event)"
          ></vs-checkbox>
        </vs-th>-->
        <vs-th style="width:10%;" sort-key="incharge">ลงรายการ</vs-th>
        <vs-th sort-key="orderNumber">เลข Order</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName">ข้อมูลชื่อผู้เช่า</vs-th>
        <vs-th sort-key="rentalPriceTotal">ยอดเช่าชุด</vs-th>
        <vs-th sort-key="bailTotal">เงินประกัน</vs-th>
        <vs-th sort-key="pickupDate">วันรับชุด</vs-th>
        <vs-th sort-key="returnDate">วันคืนชุด</vs-th>
        <!-- <vs-th sort-key="orderStatus">สถานะ</vs-th> -->
        <!-- <vs-th>วิธีชำระ</vs-th> -->
        <vs-th>คืนสถานะ</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>

      <template style="z-index:1 !important;" slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td>
            <vs-checkbox v-model="data[indextr].selected" @click="addSelectedList(indextr, $event)"></vs-checkbox>
          </vs-td>-->
          <vs-td>
            {{tr.updateBy}}
            <br />
            {{formatDate(tr.updateDateTime)}}
            <br />
            <!-- <div style="color:#9E0619; font-weight:light;">รายละเอียด</div> -->
            <br />
          </vs-td>
          <vs-td style="text-align:center;">
            <vs-button
              :href="getRedirectLink(tr.orderHeaderWebId)"
              target="blank"
              style="padding:0; font-size:11px; color: #2372CE; background-color: white !important; border: 0 white solid !important; font-weight:bold;"
            >{{tr.orderNumber}}</vs-button>
            <br />
            {{findBranchName(tr.branchId)}}
            <br />
            <!-- <div @click="changeToReturn($event, tr, indextr)" class="sm-btn-red">{{tr.orderStatus}}</div> -->
            <div v-if="tr.orderStatus === 'บันทึก' " class="sm-btn-red">{{tr.orderStatus}}</div>
            <div
              @click="redirectTo(tr.orderHeaderWebId)"
              v-else-if="tr.orderStatus === 'เก็บสิทธิ์' "
              class="sm-btn-cyan"
            >{{tr.orderStatus}}</div>
            <div v-else class="sm-btn-black">{{tr.orderStatus}}</div>
            <br />
          </vs-td>
          <!-- <vs-td>ข้อมูลสินค้า</vs-td> -->
          <vs-td style="max-width:150px; font-family: SukhumvitSet-Medium;">
            {{tr.customerName}}
            <br />
            {{tr.customerAddress}}
            <br />
            {{tr.customerPhone}}
          </vs-td>

          <vs-td
            style="padding-left:20px;"
          >{{realRentalPrice(tr.rentalPriceTotal, tr.rentalDiscount, tr.promotionDiscount)}}</vs-td>
          <vs-td style="padding-left:20px;">{{realBail(tr.bailTotal, tr.bailDiscount)}}</vs-td>

          <vs-td>{{ formatDate(tr.pickupDate)}}</vs-td>
          <vs-td>{{formatDate(tr.returnDate)}}</vs-td>
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->
          <!-- <vs-td
            style="max-width:110px !important;"
            @click="$event.stopPropagation()"
          >{{showPayment(tr.paymentMethod)}}</vs-td>-->

          <vs-td>
            <i
              v-if="tr.orderStatus === 'เก็บสิทธิ์'"
              @click="rollback($event,tr.orderHeaderWebId)"
              style="color:#0069ff; font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >reply_all</i>

            <i
              v-if="tr.orderStatus === 'บันทึก'"
              @click="toOrderDetail(tr.orderHeaderWebId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >edit</i>
          </vs-td>
          <vs-td>
            <i
              v-if="$acl.check('admin')"
              @click="openRemovePanel($event, tr)"
              style="color:red; font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >delete_forever</i>
          </vs-td>

          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import shapeFormat from "../../mixins/shapeFormat.js";
import CurrencyValue from "./Components/CurrencyValue";

export default {
  mixins: [shapeFormat],
  data() {
    return {
      removeCode: "",
      removeId: "",
      removeActive: false,
      searchDateEnd: null,
      backstatusIndex: -1,
      backstatusPopupActive: false,
      original: [],
      searchlist: [],
      permission: "",
      allBranch: [],
      allDetail: [],
      deh: [],
      currentBranch: null,
      currentUser: "guest",
      roles: [],
      takenote: "",
      takenoteIndex: 0,
      theIndex: 0,
      branchCheckbox: [],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],
      branchSelect: [],
      // >>>>>>>> UPDATE INFO >>>>>>>>
      paymentMethod: "ไม่ระบุ",
      bringBackBy: "",
      state: " รอคืนชุด",
      orderNumber: "",

      // >>>>>>>> UPDATE INFO >>>>>>>>
      remark: "",
      deduction: 0,
      bankName: "",
      accountNumber: "",
      accountName: "",
      transferContact: "",

      bailDiscount: 0,
      bailTotal: 0,

      // >>>>>>>> UPDATE INFO >>>>>>>>

      statusToReturn: { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
      statusToReturnOption: [
        { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { label: "ยกเลิก", value: "ยกเลิก" }
      ],
      notePopupActive: false,
      popupActive: false,
      searchDate: null,
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: { label: "เงินสด", value: "เงินสด" },
      payment_option: ["ไม่ระบุ", "เงินสด", "โอนเงิน"  , "Alipay/wechat" , "บัตรเครดิต"],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: []
    };
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("username"));
    this.roles = JSON.parse(localStorage.getItem("roles"));

    this.permission = this.checkPermission(this.roles);

    this.allBranch = await this.loadBranch();

    // var branch = null;
    var ord = [];

    // if (this.permission === "admin") {
    ord = await this.loadAllWebOrderShare();

    for (var m = 0; m < this.allBranch.length; m++) {
      this.branchSelect[m] = true;
    }
    // } else {
    //   branch = await this.findCurrentBranch(this.roles);
    //   ord = await this.loadBranchOrderShare(branch.branchId);
    //   console.log("branch >>>> ", branch);
    // }
    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    var theorder = await ord.filter(x => x.orderStatus === "รับจากเว็บ");
    // console.log("theorder >>> ", theorder);

    this.orders = await theorder;
    this.original = await theorder;
    this.searchlist = await theorder;

    this.branchCheckbox = await this.allBranch;

    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>

    // this.allDetail = await this.loadAllDetailShare();

    // for (var l = 0; l < this.orders.length; l++) {
    //   var list = await this.allDetail.filter(
    //     x => x.orderHeaderWebId === this.orders[l].orderHeaderWebId
    //   );
    //   this.orders[l].detail = await list;
    // }

    // console.log("all ORDER >>>>>>>>>> ", this.orders);
  },

  methods: {

    async reload(){
         var ord = [];

          ord = await this.loadAllWebOrderShare();

          for (var m = 0; m < this.allBranch.length; m++) {
            this.branchSelect[m] = true;
          }

          // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
          var theorder = await ord.filter(x => x.orderStatus === "รับจากเว็บ");
          // console.log("theorder >>> ", theorder);

          this.orders = await theorder;
          this.original = await theorder;
          this.searchlist = await theorder;

    },

    async rollback(event, id) {
      event.stopPropagation();
      this.$vs.loading({ type: "radius" });
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/rollback/" + id,
          {
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        res = null;
      }

      if (res !== null) {
        this.reload();
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
        }, 1000);
        this.popupActive = false;
      } else {
        // setTimeout(() => {
        //   this.noticeError("เกิดข้อผิดพลาด");
        // }, 500);
      }
    },
    redirectTo(id) {
      window.open("/backoffice/rental-hold/" + id, "_blank");
    },
    toOrderDetail(id) {
      window.open("/backoffice/web-order-detail/" + id, "_blank");
    },
    openRemovePanel(event, order) {
      event.stopPropagation();
      this.removeCode = "";
      this.removeId = "";
      this.removeActive = true;
      this.removeCode = order.orderNumber;
      this.removeId = order.orderHeaderWebId;
    },
    async removeOrder() {
      this.$vs.loading({ type: "radius" });
      var resp = null;
      try {
        resp = await this.$http.delete(
          this.$store.state.apiURL +
            "/api/Nbs4u0m9pehXse/weborder/" +
            this.removeId,
            this.$store.state.auth.apiHeader
        );
      } catch (err) {
        resp = null;
      }
      if (resp === null) {
        this.noticeError("ลบไม่สำเร็จ");
      } else {

        await this.reload();

        // var removedIndex = this.orders.findIndex(
        //   x => x.orderHeaderWebId === this.removeId
        // );
        // // console.log("removedIndex >>> ", removedIndex);
        // this.orders.splice(removedIndex, 1);
        // this.original.splice(removedIndex, 1);
        // this.searchlist.splice(removedIndex, 1);

        // await this.reload();
        setTimeout(() => {
          this.$vs.loading.close();
          this.closeRemovePanel();
          this.noticeSuccess("ลบสำเร็จ");
        }, 1000);
      }
    },

    closeRemovePanel() {
      this.removeActive = false;
      this.removeCode = "";
      this.removeId = "";
    },

    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    getRedirectLink(orderId) {
      return "/backoffice/web-order-detail/" + orderId;
    },
    async detailOneOrder(orderId) {
      var list = await this.allDetail.filter(
        x => x.orderHeaderWebId === orderId
      );
      // console.log("orderId >>> ", orderId, " list >>> ", list);
      return list;
    },
    theSearch(event, index) {
      this.activeb(event, index);
      this.searchBtn();
    },
    activeb(event, index) {
      var blist = this.allBranch;
      this.branchCheckbox = [];

      if (event.target.className == "choose") {
        this.branchSelect[index] = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.branchSelect[index] = true;
      }

      // ถ้า branchSelect ใส่ค่า
      for (var p = 0; p < blist.length; p++) {
        if (this.branchSelect[p] === true) {
          this.branchCheckbox.push(blist[p]);
        }
      }
    },
    searchBtn() {
      var list = [];
      var list2 = [];
      this.orders = [];
      var searchdate = this.formatDate(this.searchDate);
      var searchdateEnd = this.formatDate(this.searchDateEnd);
      // console.log("searchdateEnd >>> ", searchdateEnd);

      var startNull = false;
      var endNull = false;

      if (
        this.searchDate === "" ||
        this.searchDate === null ||
        this.searchDate === undefined
      ) {
        startNull = true;
        // console.log("**** >>> startNull ");
      }

      if (
        this.searchDateEnd === "" ||
        this.searchDateEnd === null ||
        this.searchDateEnd === undefined
      ) {
        endNull = true;
        // console.log("**** >>> endNull ");
      }

      // console.log(
      //   "startNull >>> ",
      //   startNull,
      //   " endNull >>> ",
      //   endNull,
      //   " startNull&&endNull >>> ",
      //   startNull && endNull
      // );
      // console.log(
      //   "this.searchDate >>> ",
      //   this.searchDate,
      //   " this.searchDateEnd >>> ",
      //   this.searchDateEnd
      // );

      var searchList = [];

      if (startNull && endNull) {
        searchList = this.original;
        // console.log("BOTH NULL");
      } else {
        // console.log("Either one NULL");
        list = this.original;
        var tmplist = [];
        if (!startNull) {
          tmplist = list.filter(
            s => this.formatDate(s.returnDate) >= searchdate
          );
          // console.log("startNull NOT NULL");
        } else {
          tmplist = list;
        }

        // console.log("AFTER SEARCH DATE >>>> ", tmplist);

        list2 = tmplist;
        var tmplist2 = [];
        // console.log("******* END NULL : ", endNull);
        if (!endNull) {
          tmplist2 = list2.filter(
            e => this.formatDate(e.returnDate) <= searchdateEnd
          );
          // console.log("endNull NOT NULL");
        } else {
          tmplist2 = list2;
        }

        // console.log("AFTER SEARCH DATE END >>>> ", tmplist2);

        searchList = tmplist2;
      }

      // console.log(
      //   "@ CHECK POINT  sthis.branchCheckbox.length  >>> ",
      //   this.branchCheckbox.length
      // );

      if (this.branchCheckbox.length >= 1) {
        for (var k = 0; k < searchList.length; k++) {
          for (var j = 0; j < this.branchCheckbox.length; j++) {
            if (searchList[k].branchId === this.branchCheckbox[j].branchId) {
              this.orders.push(searchList[k]);
            }
          }
        }
      }
    },
    resetBtn() {
      this.searchDate = "";
      this.searchDateEnd = "";
      this.orders = this.original;
    },
    // async reload() {
    //   var branch = null;
    //   var ord = [];
    //   this.permission = this.checkPermission(this.roles);
    //   if (this.permission === "admin") {
    //     ord = await this.loadAllWebOrderShare();
    //   } else {
    //     branch = await this.findCurrentBranch(this.roles);
    //     ord = await this.loadBranchOrderShare(branch.branchId);
    //   }
    //   // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    //   var theorder = await ord.filter(
    //     x =>
    //       x.orderStatus === "บันทึก" ||
    //       x.orderStatus === "ยกเลิก" ||
    //       x.orderStatus === "เก็บสิทธิ์"
    //   );

    //   this.orders = await theorder;
    //   this.searchlist = await theorder;
    //   this.original = await theorder;

    //   // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    // },
    changeToReturn: function(event, order, index) {
      event.stopPropagation();
      this.paymentMethod = order.paymentMethod;
      this.orderHeaderWebId = order.orderHeaderWebId;

      this.deduction = order.deduction;
      this.remark = order.remark1;
      this.bankName = order.bankName;
      this.accountNumber = order.accountNumber;
      this.accountName = order.accountName;
      this.transferContact = order.transferContact;
      this.bailTotal = order.bailTotal;
      this.bailDiscount = order.bailDiscount;

      this.theIndex = index;
      this.popupActive = true;
    },
    async update() {
      // >>>>>>>>>> Validate INFO
      if (
        this.bankName === null ||
        this.bankName === undefined ||
        this.bankName === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อธนาคาร");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        this.accountNumber === null ||
        this.accountNumber === undefined ||
        this.accountNumber === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่เลขบัญชี");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        this.accountName === null ||
        this.accountName === undefined ||
        this.accountName === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อธนาคาร");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        this.transferContact === null ||
        this.transferContact === undefined ||
        this.transferContact === ""
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบ",
          "ยังไม่ได่ใส่เบอร์ติดต่อกลับหลังโอนเงิน"
        );
        return false;
      }

      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        deduction: this.deduction,
        remark1: this.remark,
        bankName: this.bankName,
        accountNumber: this.accountNumber,
        accountName: this.accountName,
        transferContact: this.transferContact,

        bringBackBy: this.currentUser,

        updateBy: this.currentUser,

        orderStatus: "คืนชุดแล้ว"
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/bringback/" +
            this.orderHeaderWebId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 2000);
          this.popupActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 2000);
      }

      // >>>> START TO SAVE HEADER
    },
    resetValue() {
      this.paymentMethod = "ไม่ระบุ";
      this.state = "ไม่ระบุ";
      this.theIndex = -1;
      this.takenoteIndex = -1;
      this.remark = "";

      this.deduction = "";

      this.bankName = "";
      this.accountNumber = "";
      this.accountName = "";
      this.transferContact = "";
    },
    closePanel() {
      this.popupActive = false;
      this.resetValue();
    },
    changeStatus(state) {
      this.state = state;
    },
    changePayment(pay) {
      this.paymentMethod = pay;
    },
    showPayment(pay) {
      if (pay === "เงินสด" || pay === "โอนเงิน"  || pay === "Alipay/wechat" || pay === "บัตรเครดิต") {
        return pay;
      } else {
        return "ไม่ระบุ";
      }
    },

    async findCurrentBranch(roles) {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      var userrole = [];
      userrole = roles.find(x => x.roleName.includes("staff-"));
      if (userrole.length === 0 || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      // console.log(" branch_th >>>> ", branch_th);

      var allBranches = await this.allBranch;

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      return thisBranch;
    },

    findBranchName(id) {
      var b = this.allBranch.find(i => i.branchId === id);
      // console.log("@ BRANCH NAME >>> ", b, " ID >>> ", id);

      // return b.branchName;
      if (b === null || b === undefined) return "ไม่ระบุ";
      else return b.branchName;
    },

    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    checkPermission(roles) {
      var pm = roles.filter(
        x =>
          x.roleName === "owner" ||
          x.roleName === "admin" ||
          x.roleName === "accountingadmin" ||
          x.roleName === "useradmin"
      );
      if (pm.length >= 1) {
        return "admin";
      } else {
        return "staff";
      }
    },
    confirmToChangeStatus(event) {
      event.stopPropagation();
      // this.orders[this.theIndex].orderStatus = "รับชุดแล้ว";
      // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");

      this.popupActive = false;
    },
    backStatus(event, index, item) {
      event.stopPropagation();
      this.orderNumber = item.orderNumber;
      this.backstatusPopupActive = true;
      this.backstatusIndex = index;
    },
    closeBackStatusNotePanel() {
      this.backstatusPopupActive = false;
      this.resetValue();
      this.orderNumber = "";
    },
    async updateback() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.orders[this.backstatusIndex].orderHeaderWebId;
      // console.log(
      //   "ordId >>> ",
      //   ordId,
      //   " this.takenoteIndex ",
      //   this.takenoteIndex
      // );

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/backstatus/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeBackStatusNotePanel();
          }, 1000);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    takeNote(event, index) {
      event.stopPropagation();
      // console.log("@@@@@ TAKE NOTE >>> ", this.orders[index]);

      this.takenoteIndex = index;
      this.notePopupActive = true;
      this.remark = this.orders[index].remark1;
    },
    async updatenote() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.orders[this.takenoteIndex].orderHeaderWebId;
      // console.log(
      //   "ordId >>> ",
      //   ordId,
      //   " this.takenoteIndex ",
      //   this.takenoteIndex
      // );

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/takenote1/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeNotePanel();
          }, 1000);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    closeNotePanel() {
      this.notePopupActive = false;
      this.$vs.loading.close();
      this.resetValue();
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  },
  async created() {
    // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");
    // this.originalList = this.orders;

    // NOTIFICATION CHECKPOINT
    // var response = null;
    // try {
    //   response = await this.$http.post(
    //     this.$store.state.apiURL + "/api/Nbs4u0m9pehXse/weborder-checkpoint",
    //     {}
    //   );
    // } catch (error) {
    //   this.noticeError(error);
    //   return null;
    // }

    // if (response !== null) {
    //   var noti = await response.data;
    //   this.$store.commit("UPDATE_NOTIFICATION_NUMBER", noti);
    // }
  },
  components: {
    Prism,
    Datepicker,
    "v-select": vSelect,
    CurrencyValue
  },
  watch: {
    orders: {
      handler() {},
      deep: true
    },

    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    }
    // branchCheckbox: function() {
    //   this.searchBtn();
    // }
  }
};
</script>

<style>
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/* v-select  */
.clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none !important;
}
.dropdown-menu {
  margin-top: 5 !important;
  background-color: white;
}
.selected-tag {
  width: 100% !important;
}
.vs__actions {
  display: none !important;
}

.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.saved-btn {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.con-vs-popup .vs-popup {
  width: 750px !important;
}

/* v-select  */
</style>
